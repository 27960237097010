<template>
  <div class="cateGood">
      <ul>
          <li v-for="(item,index) in MenuList" :key="index" class="area">
              <div class="displayRow header">
                  <span class="cateName">{{item.title}}</span>
                  <div class="displayRow rightCate"> 
                     <p v-for="(j,k) in item.childrenClassify" :key="k">
                         <el-link :underline="false" @click="toGoods(item.classifyId,j.classifyId)">{{j.title}}</el-link>
                         <el-divider direction="vertical"></el-divider>
                     </p>
                     <el-link :underline="false" @click="toGoods(item.classifyId)">更多 >></el-link>
                  </div>
              </div>
               <el-row type="flex" class="good" justify="space-between">
      <el-col :span="4.5" v-for="(m,n) in item.Top3List" :key="n" class="col-item pointer">
          <div @click="toDetails(m.goodsId)">
         <el-image :src="m.defaultImageUrl ? m.defaultImageUrl : m.imageUrl" class="intra-img"></el-image>
        <el-link :underline="false" class="intra-name">
            <span v-if="m.isPutAway === 1"  class="redSty">【已下架】</span>{{m.title}}</el-link>
            <!-- <p class="fontSm graySty">条码：33222255</p> -->
        <div class="priceCon displayRow">
          <div class="price">
                   <span class="nowPrice priceSty">￥{{m.minPrice ? Math.round(m.minPrice) : '--'}}</span>
                   <s class="beforePrice graySty fontSm">￥{{m.maxPrice ? Math.round(m.maxPrice) : '--'}}</s>
                 </div>
          <div class="saleNum">已售{{m.salesNum}}件</div>
        </div>
        </div>
        </el-col>
    </el-row>
       <el-divider></el-divider>
    <el-row type="flex" class="good" justify="space-between">
      <el-col :span="6" v-for="(q,w) in item.Top4List" :key="w" class="top4-item pointer">
          <div class="displayRow itemSty" @click="toDetails(q.goodsId)">
               <el-image :src="q.defaultImageUrl ? q.defaultImageUrl : q.imageUrl" class="top4-img"></el-image>
               <div class="info">
                <div>
                    <el-link :underline="false" class="top4-name">
            <span v-if="q.isPutAway === 1" class="redSty">【已下架】</span>
                    {{q.title}}</el-link>
                    <!-- <p class="fontSm graySty">条码：33222255</p> -->
                </div>
                  <div class="priceCon displayRow">
           <div class="price">
                   <span class="nowPrice priceSty">￥{{q.minPrice ? Math.round(q.minPrice) : '--'}}</span>
                   <s class="beforePrice graySty fontSm">￥{{q.maxPrice ? Math.round(q.maxPrice) : '--'}}</s>
                 </div>
          <div class="saleNum">已售{{q.salesNum}}件</div>
        </div>
               </div>
          </div>
        
        </el-col>
    </el-row>   

          </li>
      </ul>

  </div>
</template>

<script>
import {getClassify} from '@/assets/js/api.js'
import { getGoodsList } from "@/assets/js/api.js";
export default {
        data(){
            return{
                MenuList:[],
            }
        },
        methods:{
            toGoods(parentId,childId){
                this.$router.push({
                    path:'/GoodsData',
                    query:{
                        parentId:parentId,
                        childId:childId
                    }
                    })
            },

            getData(goodsClassifyId,index){
                 let data = {
                    goodsClassifyId:goodsClassifyId,
                    pageNo:'1',
                    };
                getGoodsList(data).then(res =>{
                    if(res.data.code === 200){
                    let Top3List = res.data.result.pageList.slice(0,6);
                    let Top4List = res.data.result.pageList.slice(6,10);
                    this.MenuList[index].Top3List = Top3List;
                    this.MenuList[index].Top4List = Top4List;
                    }
                    }) 
            },
                      //获取商品详情
  toDetails(classifyId){
        let url= this.$router.resolve({
    name: "DetailsData",  
       query:{
        classifyId:classifyId
      }
  });
  // console.log(url,'--')
   window.open(url.href, "_blank");
    // this.$router.push({
    //   path:'/DetailsData',
    //   query:{
    //     classifyId:classifyId
    //   }
    // })
  }

        },
        created(){
             //获取全部分类
    getClassify().then((res) => {
      if (res.data.code === 200) {
        this.MenuList = res.data.result;
        this.MenuList.forEach((item,index)=> {
            if(item.childrenClassify && item.childrenClassify.length > 5){
                 item.childrenClassify = item.childrenClassify.slice(0,5);
            }
            this.$set(item,'Top3List',[]);
            this.$set(item,'Top4List',[]);
            this.getData(item.classifyId,index);
           
        });
      }
    });
        //    this.MenuList = MenuList.length > 5 ? MenuList.slice(0,5) : MenuList; 
        },

}
</script>

<style scoped>
.header{
justify-content: space-between;
padding-bottom: 5px;
border-bottom: 1px solid #eee;
}
.cateName{
    padding-left: 10px;
    border-left: 5px solid #0388DD;
    font-weight: 600;
    color:#1a1a1a;
    font-size: 24px;
}
.rightCate{
color:#666;
font-size: 14px;
}
.good{
    padding-top:8px;
}
.col-item{
     width:166px;
}
.intra-img{
    width:100%;
    height: 166px;
}
.intra-name{
    width:166px;
      width:166px;
    display:block;
    overflow: hidden;
text-overflow:ellipsis;
white-space: nowrap;
font-size: 14px;
padding:5px;
}
.priceCon{
    justify-content: space-between;
}
.saleNum{
    font-size: 12px;
    color:#aaa;
}
.area{
    margin-bottom: 50px;
}

.top4-img{
    width:107px;
    height: 107px;
}
.top4-name{
    width:150px;
    display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
overflow: hidden;
}
.itemSty{
    align-items: inherit;
}
.info{
    width:140px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

</style>