<template>
  <div class="wrapper">
        <div class="left">
      <el-menu  class="el-menu-vertical-demo leftCon" :collapse="isCollapse">
  <el-submenu v-for="(item,index) in menuList" :key="index" :index="index+''" class="menuSub">
    <template slot="title">
    <div class="displayRow">
      <p class="titleItem">{{item.title}}</p>
      <i class="el-icon-arrow-right"></i></div>
    </template>
    <div class="submenuCon">
        <div class="title">{{item.title}} 系列</div>
       <div class="subMenu displayRow">

          <div class="cateLeft">
             <el-button type="primary" class="CateItem" 
             v-for="(j,k) in item.childrenClassify" 
             :key="k"
             @click="toGoods(item.classifyId,j.classifyId)">{{j.title}}</el-button>
          </div>

          <div class="goodRight">
            <div class="area right2">
               <div class="rightTitle">
                <i class="el-icon-search icon-title"></i>
                <span>热销商品</span>
              </div>
              <ul class="displayRow top2Con">
                <li class="top2Item" v-for="(e,r) in item.Top2List" :key="r" @click="toDetails(e.goodsId)">
                  <img class="top2Img" :src="e.defaultImageUrl ? e.defaultImageUrl : e.imageUrl"  alt="">
                    <el-link  class="top2Name" :underline="false">{{e.title}}</el-link>
                  <p class="top2Price priceSty">￥{{e.minPrice ? e.minPrice : '--'}}</p>
                </li>
              </ul>
            </div>
          </div>
       </div>
    </div>
  </el-submenu>
</el-menu>
    </div>
  </div>
</template>

<script>
import {getClassify} from '@/assets/js/api.js'
import { getGoodsList } from "@/assets/js/api.js";
export default {
data(){
    return{
         isCollapse: true,
         menuList:[]
    }
},
methods:{
  toGoods(parentId,childId){
    this.$router.push({
      path:'/GoodsData',
      query:{
        parentId:parentId,
        childId:childId
      }
    })
  },
        getData(goodsClassifyId,index){
                 let data = {
                    goodsClassifyId:goodsClassifyId,
                    pageNo:'1',
                    };
                getGoodsList(data).then(res =>{
                    if(res.data.code === 200){
                    let Top2List = res.data.result.pageList.slice(0,6);
                    this.menuList[index].Top2List = Top2List;
                    }
                    }) 
            },
             //获取商品详情
  toDetails(classifyId){
      let url= this.$router.resolve({
    name: "DetailsData",  
       query:{
        classifyId:classifyId
      }
  });
  // console.log(url,'--')
   window.open(url.href, "_blank");
    // this.$router.push({
    //   path:'/DetailsData',
    //   query:{
    //     classifyId:classifyId
    //   }
    // })
  }
},
created(){
      //获取全部分类
    getClassify().then((res) => {
      if (res.data.code === 200) {
        this.menuList = res.data.result.slice(0,7);
        this.menuList.forEach((item,index)=>{
          this.$set(item,'Top2List',[]);
          this.getData(item.classifyId,index);
        })
      }
    });
}
}
</script>

<style scoped>
.leftCon{
  background-color: #f1f1f1;
}
.titleItem{
    width:100px;
}
.submenuCon{
  width:830px;
  padding:20px;
  box-sizing: border-box;
  background-color: #fff;
}
.title{
  border-bottom: 1px solid #eee;
  padding:5px;
}
.subMenu{
  padding-top:10px;
}
.subMenu{
  align-items: inherit;
}
.CateItem{
  /* background-color: #0388DD; */
  /* color:#fff; */
  width:120px;
  padding:8px 0;
  text-align: center;
  float: left;
  margin-right: 15px;
  margin-bottom: 10px;
  font-size: 14px;
  margin-left: 0;
  /* cursor: pointer; */
}
.cateLeft{
  flex:1.2;
}
.goodRight{
  flex:2;
}
.area{
  margin-bottom: 20px;
  color:#666;
  font-size: 14px;
}
.rightTitle{
  border-bottom: 1px dashed #eee;
  padding-bottom:5px;
}
.icon-title{
  margin-right: 5px;
}
.top1Con,.top2Con{
  flex-wrap: wrap;
}
.top1Item{
  margin-right: 10px;
  margin-top:5px;
}

.top2Item{
   margin-top:5px; 
   margin-right: 30px;
   cursor: pointer;
}
.top2Img{
  width:103px;
  height: 103px;
  border-radius: 10px;
}
.top2Name{
    width:103px;
    display:block;
    overflow: hidden;
text-overflow:ellipsis;
white-space: nowrap;
font-size: 12px;
padding:5px;

}
.top2Price{
  text-align: center;
}
</style>