<template>
  <div class="homeData">
    <div class="area top displayRow">
      <Menu />
      <div class="swiperCon">
        <el-carousel width="100%" height="390px">
          <el-carousel-item v-for="(item, index) in swiperImg" :key="index">
            <el-image :src="item.img" fit="fill" class="swperImg"></el-image>
          </el-carousel-item>   
        </el-carousel>
      </div>
    </div>

    <div class="area">
      <el-tabs v-model="activeName" stretch @tab-click="toSort">
        <el-tab-pane
          v-for="(item, index) in tabList"
          :key="index"
          :label="item.val"
          :name="index+''"
        >
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :span="4" v-for="(j, k) in goodList" :key="k" class="pointer" >
              <div @click="toDetails(j.goodsId)">
              <el-image  :src="
                  j.defaultImageUrl ? j.defaultImageUrl : j.imageUrl
                " class="intra-img">
                </el-image>
              <p class="intra-disc"><span v-if="j.isPutAway === 1" class="redSty">【已下架】</span>{{ j.title }}</p>
              <!-- <p class="fontSm graySty">条码：33222255</p> -->
              <div class="priceCon displayRow">
                 <div class="price">
                  <span class="nowPrice priceSty"
                    >￥{{ j.minPrice ? Math.round(j.minPrice) : '--' }}</span
                  >
                  <s class="beforePrice graySty fontSm"
                    >￥{{ j.maxPrice ? Math.round(j.maxPrice) : '--' }}</s
                  >
                </div>
                <div class="saleNum ">销量{{ j.salesNum }}笔</div>
              </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="area">
      <CateGood />
    </div>
  </div>
</template>
<script>
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?5d6b1b39fc8f63765c4a2e30df4bd41d";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
</script>
<script>
import Menu from "@/components/layOut/Menu";
import CateGood from "@/components/layOut/CateGood";
import { getGoodsList,checkTokenState,getCar } from "@/assets/js/api.js";
import Bus from "@/assets/js/bus.js";
export default {
  name:'HomeData',
  components: {
    Menu,
    CateGood,
  },
  data() {
    return {
      swiperImg: [
        { id: 0, img: require("@/assets/image/1.jpg") },
        { id: 1, img: require("@/assets/image/2.jpg") },
      ],
      tabList: [
        { key: "sortNum", val: "商城推荐" },
        { key: "putAwayDate", val: "新品上架" },
        { key: "salesNum", val: "热卖商品" },
      ],
      activeName: "0",
      goodList:[],
    };
  },
  methods: {
    toSort(){
      let index = +this.activeName;
      let param = {field:this.tabList[index].key,sort:'DESC',pageSize:5};
      this.getData(param);
    },
    getData(param) {
      // this.GoodLoading = true;
      getGoodsList(param)
        .then((res) => {
          if (res.data.code === 200) {
            this.goodList = res.data.result.pageList;
          }
        })
        .finally(() => {
          this.GoodLoading = false;
        });
  },
  
  //获取商品详情
  toDetails(classifyId){
    let url= this.$router.resolve({
    name: "DetailsData",  
       query:{
        classifyId:classifyId
      }
  });
 
   window.open(url.href, "_blank");
  }
  },
  	
  created() {
    let userInfo  = localStorage.getItem('UserInfo') ? JSON.parse(localStorage.getItem('UserInfo')) : '';
    if(userInfo){
       checkTokenState(userInfo.token).then(res =>{
         if(res.data.code === 200 && res.data.message !== ''){
           localStorage.removeItem('UserInfo');
         }else{
             getCar(userInfo.token).then(res =>{
        if(res.data.code === 200){
          let len = res.data.result.cartList.length;
            Bus.$emit('e-shopCarLen',len);
        }
      })
         }
       })
    }
      this.toSort();
  },
};
</script>


<style scoped>
.homeData {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.swiperCon {
  width: 85%;
}
.swperImg{
  height: 390px;
}
.top {
  align-items: flex-start;
  justify-content: space-between;
}
.area {
  margin-bottom: 30px;
}
.intra-img {
  width: 197px;
  height: 197px;
  padding-bottom: 10px;
}
.intra-disc {
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.priceCon {
  padding-top: 10px;
  justify-content: space-between;
}
.priceNum {
  font-size: 18px;
}
.saleNum {
  font-size: 12px;
  color: #bbb;
}
</style>